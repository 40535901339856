import Navbar from "../../components/navbar/navbar";
import Home from "../../containers/home/home";

const HomeIndex = () => {

  return (
    <>
      <Navbar />
      <div className="flex justify-center">
        <div className="w-[85%] md:w-[75%] bg-white rounded-[10px] md:rounded-[20px]">
          <Home />
        </div>
      </div>
    </>
  )
}

export default HomeIndex;