import { Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUserAuth } from "../../hooks/useAuthContextProvider";
import { updateMentorProfile } from "../../redux/mentors/mentorsActions";

type ProfileModalProps = {
    isProfileModalOpen: boolean;
    setIsProfileModalOpen: (val: boolean) => void;
    profileModalContent: any;
    setProfileModalContent: (val: any) => void;
}

const ProfileModal = ({
    isProfileModalOpen,
    setIsProfileModalOpen,
    profileModalContent,
    setProfileModalContent
}: ProfileModalProps) => {
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");

    const [college, setCollege] = useState<string>("")
    const [gender, setGender] = useState<string>("");

    const [profile, setProfile] = useState<any>(null);
    const [banner, setBanner] = useState<any>(null)

    const [profilePreview, setProfilePreview] = useState<any>(null)
    const [bannerPreview, setBannerPreview] = useState<any>(null)

    const [idCard, setIdCard] = useState<any>(null)
    const [idCardPreview, setIdCardPreview] = useState<any>(null)

    const { user } = useUserAuth()

    const dispatch = useDispatch();

    const {
        isUpdatingMentorProfile,
        updateMentorProfileResp,
        updateMentorProfileRespStatus,
        updateMentorProfileError,
        updateMentorProfileErrorMsg,
    } = useSelector((state: any) => state.mentors)

    useEffect(() => {
        if (updateMentorProfileRespStatus === 200) {
            setName("")
            setDescription("")
            setCollege("")
            setGender("")
            setProfile(null)
            setBanner(null)
            setProfilePreview(null)
            setBannerPreview(null)
            setIdCard(null)
            setIdCardPreview(null)
            setProfileModalContent({})
            setIsProfileModalOpen(false)
        } else if (updateMentorProfileError) {
            alert(updateMentorProfileErrorMsg)
        }
    }, [isUpdatingMentorProfile])

    useEffect(() => {
        if (profileModalContent?.mentorName) {
            setName(profileModalContent?.mentorName)
            setDescription(profileModalContent?.description)
            setCollege(profileModalContent?.college)
            setProfile(profileModalContent?.image)
            setBanner(profileModalContent?.bannerImage)
            setProfilePreview(profileModalContent?.image)
            setBannerPreview(profileModalContent?.bannerImage)
            setIdCard(profileModalContent?.idCard || null)
            setIdCardPreview(profileModalContent?.idCard || null)
            setGender(profileModalContent?.gender)
        }
    }, [profileModalContent])

    const handleSave = (e: any) => {
        e.preventDefault()
        dispatch(updateMentorProfile({
            name: name,
            description: description,
            gender: gender,
            college: college,
            profile: profile,
            banner: banner,
            idCard: idCard,
            mentorId: user.uid
        }))
    }

    return (
        <Modal
            open={isProfileModalOpen}
            onClose={() => {
                setName("")
                setDescription("")
                setCollege("")
                setProfile(null)
                setBanner(null)
                setProfilePreview(null)
                setBannerPreview(null)
                setIdCard(null)
                setIdCardPreview(null)
                setProfileModalContent({})
                setIsProfileModalOpen(false)
            }}
        >
            <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white p-5 rounded h-[500px] overflow-y-auto">
                <div className="text-2xl font-bold">
                    EDIT
                </div>
                <form className="flex flex-col mt-5" onSubmit={handleSave}>
                    <input className="border-none outline-none bg-[#D9D9D9] rounded mb-2 px-5 py-2" type="text" placeholder="Name" value={name} onChange={(e: any) => setName(e.target.value)} required />
                    <input className="border-none outline-none bg-[#D9D9D9] rounded mb-2 px-5 py-2" type="text" placeholder="Description" value={description} onChange={(e: any) => setDescription(e.target.value)} required />
                    <input className="border-none outline-none bg-[#D9D9D9] rounded mb-2 px-5 py-2" type="text" placeholder="College" value={college} onChange={(e: any) => setCollege(e.target.value)} required />
                    <div className="flex items-center">
                        Male<input className="border-none outline-none bg-[#D9D9D9] rounded mr-5" type="radio" name="gender" value="male" onChange={(e: any) => setGender(e.target.value)} checked={"male" === gender} />
                        Female<input className="border-none outline-none bg-[#D9D9D9] rounded" type="radio" name="gender" value="female" onChange={(e: any) => setGender(e.target.value)} checked={"female" === gender} />
                    </div>
                    Profile
                    {profilePreview && <img className="w-full object-contain h-[100px]" src={profilePreview} alt="profile" />}
                    <input className="border-none outline-none bg-[#D9D9D9] w-full max-w-[300px] md:max-w-full rounded mb-2 px-5 py-2" type="file" placeholder="Profile" accept="image/*" onChange={(e: any) => {
                        setProfilePreview(URL.createObjectURL(e.target.files[0]))
                        setProfile(e.target.files[0])
                    }} />
                    Banner
                    {bannerPreview && <img className="w-full object-cover h-[110px]" src={bannerPreview} alt="banner" />}
                    <input className="border-none outline-none bg-[#D9D9D9] w-full max-w-[300px] md:max-w-full rounded mb-2 px-5 py-2" type="file" placeholder="Banner" accept="image/*" onChange={(e: any) => {
                        setBannerPreview(URL.createObjectURL(e.target.files[0]))
                        setBanner(e.target.files[0])
                    }} />
                    Id Card
                    {idCardPreview && <img className="w-full object-contain h-[120px]" src={idCardPreview} alt="idcard" />}
                    <input className="border-none outline-none bg-[#D9D9D9] w-full max-w-[300px] md:max-w-full rounded mb-2 px-5 py-2" type="file" placeholder="Id Card" accept="image/*" onChange={(e: any) => {
                        setIdCardPreview(URL.createObjectURL(e.target.files[0]))
                        setIdCard(e.target.files[0])
                    }} />
                    <div className="flex justify-center">
                        <button type="submit" className="border-none outline-none bg-[#909090] text-white font-bold px-5 py-2 rounded" disabled={isUpdatingMentorProfile}>
                            {(isUpdatingMentorProfile) ? <div className="spinner-border animate-spin inline-block w-8 h-8 border-whtie-4 rounded-full" role="status">
                            </div> : "Save"}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default ProfileModal;