import { Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUserAuth } from "../../hooks/useAuthContextProvider";
import { addMentorPost, resetMentorPostResponses, updateMentorPost } from "../../redux/mentors/mentorsActions";

type PostModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (val: boolean) => void;
  modalContent: any;
  setModalContent: (val: any) => void;
}

const PostModal = ({
  isModalOpen,
  setIsModalOpen,
  modalContent,
  setModalContent
}: PostModalProps) => {
  const [title, setTitle] = useState<string>("")
  const [description, setDescription] = useState<string>("")
  const [image, setImage] = useState<any>(null);
  const [imagePreview, setImagePreview] = useState<any>(null)

  const [video, setVideo] = useState<string>("")

  const [postType, setPostType] = useState<string>("")

  useEffect(() => {
    if (modalContent?.title) {
      setTitle(modalContent?.title)
      setDescription(modalContent?.description)
      const temp: any = {}
      for (let i = 0; i < modalContent?.images?.length; i++) {
        temp[i] = modalContent?.images[i]
      }
      setImagePreview(temp)
      setVideo(modalContent?.video)
      setPostType(modalContent?.type === 1 ? "photo" : "video")
    }
  }, [modalContent]);

  const { user } = useUserAuth()

  const dispatch = useDispatch()

  const {
    isAddingMentorPost,
    addMentorPostRespStatus,
    addMentorPostError,
    addMentorPostErrorMsg,
    isUpdatingMentorPost,
    updateMentorPostRespStatus,
    updateMentorPostError,
    updateMentorPostErrorMsg,
  } = useSelector((state: any) => state.mentors)

  const handleSave = (e: any) => {
    e.preventDefault();
    if (modalContent?.title) {
      dispatch(updateMentorPost({
        title: title,
        description: description,
        images: image,
        updatedAt: new Date(),
        mentorId: user.uid,
        postId: modalContent?.postId,
        postType: postType,
        video: video
      }))
    } else {
      dispatch(addMentorPost({
        createdAt: new Date(),
        description: description,
        images: image,
        likedBy: [],
        mentorId: user.uid,
        show: true,
        title: title,
        viewCount: 0,
        updatedAt: new Date(),
        likes: 500 + Math.round(Math.random() * 200),
        views: 10000 + Math.round(Math.random() * 500),
        postType: postType,
        video: video
      }))
    }
  }
  useEffect(() => {
    if (addMentorPostRespStatus === 200) {
      setModalContent({})
      setIsModalOpen(false)
      setImage(null)
      setImagePreview(null)
      setDescription("")
      setTitle("")
      setPostType("")
      setVideo("")
      dispatch(resetMentorPostResponses())
    } else if (addMentorPostError) {
      alert(addMentorPostErrorMsg)
    }
  }, [isAddingMentorPost])

  useEffect(() => {
    if (updateMentorPostRespStatus === 200) {
      setModalContent({})
      setIsModalOpen(false)
      setImage(null)
      setImagePreview(null)
      setDescription("")
      setTitle("")
      setPostType("")
      setVideo("")
      dispatch(resetMentorPostResponses())
    } else if (updateMentorPostError) {
      alert(updateMentorPostErrorMsg)
    }
  }, [isUpdatingMentorPost])

  return (
    <Modal
      open={isModalOpen}
      onClose={() => {
        setModalContent({})
        setIsModalOpen(false)
        setImage(null)
        setImagePreview(null)
        setDescription("")
        setTitle("")
        setPostType("")
        setVideo("")
      }}
    >
      <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white p-5 rounded">
        <div className="text-2xl font-bold">
          {modalContent?.title ? "Edit" : "Add"}
        </div>
        <form className="flex flex-col mt-5" onSubmit={handleSave}>
          <input className="border-none outline-none bg-[#D9D9D9] rounded mb-2 px-5 py-2" type="text" placeholder="Title" value={title} onChange={(e: any) => setTitle(e.target.value)} required />
          <textarea className="border-none outline-none bg-[#D9D9D9] rounded mb-2 px-5 py-2" placeholder="Description" value={description} onChange={(e: any) => setDescription(e.target.value)} required />
          <div className="flex items-center">
            Photos<input className="border-none outline-none bg-[#D9D9D9] rounded mr-5" type="radio" name="gender" value="photo" onChange={(e: any) => {
              setVideo("")
              setPostType(e.target.value)
            }} checked={"photo" === postType} required={true} />
            Video<input className="border-none outline-none bg-[#D9D9D9] rounded" type="radio" name="gender" value="video" onChange={(e: any) => {
              setVideo(modalContent?.video || "")
              setPostType(e.target.value)
            }} checked={"video" === postType} required={true} />
          </div>
          {postType === "video" && <input className="border-none outline-none bg-[#D9D9D9] rounded mb-2 px-5 py-2" type="text" placeholder="Video" value={video} onChange={(e: any) => setVideo(e.target.value)} required />}
          {postType === "photo" && <div>
            {imagePreview && Object.keys(imagePreview).map((each: any) => <img className="w-full object-contain h-[100px]" src={typeof (imagePreview[each]) === "string" ? imagePreview[each] : URL.createObjectURL(imagePreview[each])} alt="post" />)}
            <input className="border-none outline-none bg-[#D9D9D9] w-full max-w-[300px] md:max-w-full rounded mb-2 px-5 py-2" type="file" placeholder="Image" accept="image/*" multiple={true} onChange={(e: any) => {
              setImagePreview(e.target.files)
              setImage(e.target.files)
            }} required={modalContent?.image === ""} />
          </div>}
          <div className="flex justify-center">
            <button type="submit" className="border-none outline-none bg-[#909090] text-white font-bold px-5 py-2 rounded" disabled={isAddingMentorPost || isUpdatingMentorPost}>
              {(isAddingMentorPost || isUpdatingMentorPost) ? <div className="spinner-border animate-spin inline-block w-8 h-8 border-whtie-4 rounded-full" role="status">
              </div> : "Save"}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}
export default PostModal;