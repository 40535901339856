import {
  put, call,
  takeLatest,
} from 'redux-saga/effects';
import { db } from '../../utils/firebaseConfig';
import { collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { storage } from '../../utils/devConfig';
import {
  GET_MENTOR_INIT,
  GET_MENTOR_POSTS_INIT,
  ADD_MENTOR_POST_INIT,
  UPDATE_MENTOR_POST_INIT,
  UPDATE_MENTOR_PROFILE_INIT,
  DELETE_MENTOR_POST_INIT
} from '../actionTypes';
import {
  getMentorSuccess,
  getMentorFailure,
  getMentorPostsSuccess,
  getMentorPostsFailure,
  addMentorPostSuccess,
  addMentorPostFailure,
  updateMentorPostSuccess,
  updateMentorPostFailure,
  updateMentorProfileSuccess,
  updateMentorProfileFailure,
  deleteMentorPostSuccess,
  deleteMentorPostFailure
} from './mentorsActions';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';

function* getMentor(action: any): any {
  try {
    const mentor = async () => {
      const q = query(collection(db, "MentorProfile"), where("mentorId", "==", action.payload.params.mentorId));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size === 0) {
        return {
          status: 404,
          message: "You are not listed yet :/"
        }
      } else {
        return querySnapshot.docs[0].data()
      }
    }

    const data = yield call(mentor);
    if (data?.mentorId) {
      yield put(getMentorSuccess(data));
    } else {
      yield put(getMentorFailure(data));
    }
  } catch (err: any) {
    yield put(getMentorFailure(err.message));
  }
}

export function* getMentorSagas() {
  yield takeLatest(GET_MENTOR_INIT, getMentor);
}

function* getMentorPosts(action: any): any {
  try {
    const posts = async () => {
      const q = query(collection(db, "MentorPosts"), where("mentorId", "==", action.payload.params.mentorId));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size === 0) {
        return {
          status: 404,
          message: "You haven't posted yet!"
        }
      } else {
        let arr: any = [];
        querySnapshot.forEach((data) => {
          arr.push(data.data())
        })
        arr.sort((a: any, b: any) => b.createdAt.toDate() - a.createdAt.toDate())
        return arr;
      }
    }

    const data = yield call(posts);
    if (data?.[0]) {
      yield put(getMentorPostsSuccess(data));
    } else {
      yield put(getMentorPostsFailure(data));
    }
  } catch (err: any) {
    yield put(getMentorPostsFailure({
      status: 400,
      message: "Something went wrong"
    }));
  }
}

export function* getMentorPostsSagas() {
  yield takeLatest(GET_MENTOR_POSTS_INIT, getMentorPosts);
}

function* addMentorPost(action: any): any {
  try {
    const add = async () => {
      try {
        const params = action.payload.params;
        const docRef = doc(collection(db, "MentorPosts"))
        let responses: any = []
        if (action.payload.params.postType === "photo") {
          for (let i = 0; i < Object.keys(action.payload.params.images).length; i++) {
            const imageRef = ref(storage, `Mentors/${params.mentorId}/Posts/${docRef.id}/${Object.keys(action.payload.params.images)[i]}`);
            await uploadBytes(imageRef, params.images[Object.keys(params.images)[i]])
            const data = await getDownloadURL(imageRef)
            responses.push(data)
          }
        }

        const { postType, ...rest } = action.payload.params

        await setDoc(docRef, {
          ...rest,
          image: responses[0] || "",
          images: responses,
          postId: docRef.id,
          type: action.payload.params.postType === "photo" ? 1 : 2
        })
        return true
      } catch (e) {
        console.log(e)
        return {
          status: 500,
          message: "Could not upload post"
        }
      }
    }

    const data = yield call(add);
    if (data === true) {
      yield put(addMentorPostSuccess(data));
    } else {
      yield put(addMentorPostFailure(data));
    }
  } catch (err: any) {
    yield put(addMentorPostFailure({
      status: 400,
      message: "Something went wrong"
    }));
  }
}

export function* addMentorPostSagas() {
  yield takeLatest(ADD_MENTOR_POST_INIT, addMentorPost);
}

function* updateMentorPost(action: any): any {
  try {
    const update = async () => {
      try {
        const params = action.payload.params;
        let url = "";
        let responses: any = []
        const docRef = doc(db, "MentorPosts", params.postId)
        if (action.payload.params.postType === "photo") {
          if (Object.keys(action.payload.params.images)?.length > 0) {
            const docRefGet = await getDoc(docRef)
            const docData = docRefGet.data()
            if (docData?.images?.length > 0) {
              for (let i = 0; i < docData?.images?.length; i++) {
                await deleteObject(ref(storage, `Mentors/${params.mentorId}/Posts/${params.postId}/${i}`))
              }
            }
          }
          for (let i = 0; i < Object.keys(action.payload.params.images).length; i++) {
            const imageRef = ref(storage, `Mentors/${params.mentorId}/Posts/${params.postId}/${Object.keys(action.payload.params.images)[i]}`);
            await uploadBytes(imageRef, params.images[Object.keys(params.images)[i]])
            const data = await getDownloadURL(imageRef)
            url = data
            responses.push(data)
          }
          const { title, description } = params;
          if (url !== "") {
            await updateDoc(docRef, {
              title: title,
              description: description,
              image: responses[0],
              images: responses,
              updatedAt: new Date(),
              video: "",
              type: 1
            })
          } else {
            await updateDoc(docRef, {
              title: title,
              description: description,
              updatedAt: new Date(),
              video: "",
              type: 1
            })
          }
        } else {
          const docRef = doc(db, "MentorPosts", params.postId)
          const docRefGet = await getDoc(docRef)
          const docData = docRefGet.data()
          if (docData?.images?.length > 0) {
            for (let i = 0; i < docData?.images?.length; i++) {
              await deleteObject(ref(storage, `Mentors/${params.mentorId}/Posts/${params.postId}/${i}`))
            }
          }
          const { title, description, video } = params;
          await updateDoc(docRef, {
            title: title,
            description: description,
            video: video,
            updatedAt: new Date(),
            type: 2,
            image: "",
            images: []
          })
        }

        return true
      } catch (e) {
        return {
          status: 500,
          message: "Could not upload post"
        }
      }
    }

    const data = yield call(update);
    if (data === true) {
      yield put(updateMentorPostSuccess(data));
    } else {
      yield put(updateMentorPostFailure(data));
    }
  } catch (err: any) {
    yield put(updateMentorPostFailure({
      status: 400,
      message: "Something went wrong"
    }));
  }
}

export function* updateMentorPostSagas() {
  yield takeLatest(UPDATE_MENTOR_POST_INIT, updateMentorPost);
}

function* updateMentorProfile(action: any): any {
  try {
    const update = async () => {
      try {
        const params = action.payload.params;
        let profile = action.payload.params.profile === null ? "" : action.payload.params.profile;
        let banner = action.payload.params.banner === null ? "" : action.payload.params.banner;
        let idCard = action.payload.params.idCard === null ? "" : action.payload.params.idCard
        if (action.payload.params.profile && typeof (profile) === "object") {
          const imageRef = ref(storage, `Mentors/${params.mentorId}/profile`);
          await uploadBytes(imageRef, params.profile)
          const response = await getDownloadURL(imageRef)
          profile = response;
        }

        if (action.payload.params.banner && typeof (banner) === "object") {
          const imageRef = ref(storage, `Mentors/${params.mentorId}/banner`);
          await uploadBytes(imageRef, params.banner)
          const response = await getDownloadURL(imageRef)
          banner = response;
        }

        if (action.payload.params.idCard && typeof (idCard) === "object") {
          const imageRef = ref(storage, `Mentors/${params.mentorId}/idCard`);
          await uploadBytes(imageRef, params.idCard)
          const response = await getDownloadURL(imageRef)
          idCard = response;
        }

        const docRef = doc(db, "MentorProfile", params.mentorId)
        const { name, description, college, gender } = params;
        await updateDoc(docRef, {
          mentorName: name,
          description: description,
          college: college,
          image: profile,
          bannerImage: banner,
          idCard: idCard,
          updatedAt: new Date(),
          gender: gender,
          collegeId: ""
        })
        return true
      } catch (e) {
        return {
          status: 500,
          message: "Could not complete action"
        }
      }
    }

    const data = yield call(update);
    if (data === true) {
      yield put(updateMentorProfileSuccess(data));
    } else {
      yield put(updateMentorProfileFailure(data));
    }
  } catch (err: any) {
    yield put(updateMentorProfileFailure({
      status: 400,
      message: "Something went wrong"
    }));
  }
}

export function* updateMentorProfileSagas() {
  yield takeLatest(UPDATE_MENTOR_PROFILE_INIT, updateMentorProfile);
}

function* deleteMentorPost(action: any): any {
  try {
    const deleteP = async () => {
      try {
        const { params } = action.payload
        const docRef = doc(db, "MentorPosts", params.mentorPost)
        const docRefGet = await getDoc(docRef)
        const docData = docRefGet.data()
        if (docData?.images?.length > 0) {
          for (let i = 0; i < docData?.images?.length; i++) {
            await deleteObject(ref(storage, `Mentors/${params.mentorId}/Posts/${params.mentorPost}/${i}`))
          }
        }
        await deleteDoc(doc(db, "MentorPosts", action.payload.params.mentorPost))
        return true
      } catch (e) {
        return {
          status: 500,
          message: "Could not complete action"
        }
      }
    }

    const data = yield call(deleteP);
    if (data === true) {
      yield put(deleteMentorPostSuccess(data));
    } else {
      yield put(deleteMentorPostFailure(data));
    }
  } catch (err: any) {
    yield put(deleteMentorPostFailure({
      status: 400,
      message: "Something went wrong"
    }));
  }
}

export function* deleteMentorPostSagas() {
  yield takeLatest(DELETE_MENTOR_POST_INIT, deleteMentorPost);
}