import { UserAuthContextProvider } from './hooks/useAuthContextProvider';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AuthIndex from './screens/auth';
import ProtectedRoute from './components/common/privateRoute';
// import Home from './screens/home/home';
import LoggedInRoute from './components/common/loggedInRoute';
import NotFound from './components/common/notFound';
import HomeIndex from './screens/home';

function App() {
  return (
    <div className="App">
      <UserAuthContextProvider>
        <Router>
          <Routes>
            <Route path="/login" element={
              <LoggedInRoute>
                <AuthIndex />
              </LoggedInRoute>
            }
            />
            <Route path="/" element={
              <ProtectedRoute>
                <HomeIndex />
              </ProtectedRoute>
            }
            />
            <Route path="*" element={
              <NotFound />
            }
            />
          </Routes>
        </Router>
      </UserAuthContextProvider>
    </div>
  );
}

export default App;
