import {
  GET_MENTOR_INIT,
  GET_MENTOR_SUCCESS,
  GET_MENTOR_FAILURE,
  GET_MENTOR_POSTS_INIT,
  GET_MENTOR_POSTS_SUCCESS,
  GET_MENTOR_POSTS_FAILURE,
  ADD_MENTOR_POST_INIT,
  ADD_MENTOR_POST_SUCCESS,
  ADD_MENTOR_POST_FAILURE,
  UPDATE_MENTOR_POST_INIT,
  UPDATE_MENTOR_POST_SUCCESS,
  UPDATE_MENTOR_POST_FAILURE,
  RESET_MENTOR_POST_RESPONSES,
  UPDATE_MENTOR_PROFILE_INIT,
  UPDATE_MENTOR_PROFILE_SUCCESS,
  UPDATE_MENTOR_PROFILE_FAILURE,
  DELETE_MENTOR_POST_INIT,
  DELETE_MENTOR_POST_SUCCESS,
  DELETE_MENTOR_POST_FAILURE
} from "../actionTypes";

export const getMentorSuccess = (data: any) => {
  return {
    type: GET_MENTOR_SUCCESS,
    data
  }
}

export const getMentorFailure = (error: any) => {
  return {
    type: GET_MENTOR_FAILURE,
    error
  }
}

export const getMentor = (params: any) => {
  const payload = { params }
  return {
    type: GET_MENTOR_INIT,
    payload
  }
}

export const getMentorPostsSuccess = (data: any) => {
  return {
    type: GET_MENTOR_POSTS_SUCCESS,
    data
  }
}

export const getMentorPostsFailure = (error: any) => {
  return {
    type: GET_MENTOR_POSTS_FAILURE,
    error
  }
}

export const getMentorPosts = (params: any) => {
  const payload = { params }
  return {
    type: GET_MENTOR_POSTS_INIT,
    payload
  }
}

export const addMentorPostSuccess = (data: any) => {
  return {
    type: ADD_MENTOR_POST_SUCCESS,
    data
  }
}

export const addMentorPostFailure = (error: any) => {
  return {
    type: ADD_MENTOR_POST_FAILURE,
    error
  }
}

export const addMentorPost = (params: any) => {
  const payload = { params }
  return {
    type: ADD_MENTOR_POST_INIT,
    payload
  }
}

export const updateMentorPostSuccess = (data: any) => {
  return {
    type: UPDATE_MENTOR_POST_SUCCESS,
    data
  }
}

export const updateMentorPostFailure = (error: any) => {
  return {
    type: UPDATE_MENTOR_POST_FAILURE,
    error
  }
}

export const updateMentorPost = (params: any) => {
  const payload = { params }
  return {
    type: UPDATE_MENTOR_POST_INIT,
    payload
  }
}

export const resetMentorPostResponses = () => {
  return {
    type: RESET_MENTOR_POST_RESPONSES
  }
}

export const updateMentorProfileSuccess = (data: any) => {
  return {
    type: UPDATE_MENTOR_PROFILE_SUCCESS,
    data
  }
}

export const updateMentorProfileFailure = (error: any) => {
  return {
    type: UPDATE_MENTOR_PROFILE_FAILURE,
    error
  }
}

export const updateMentorProfile = (params: any) => {
  const payload = { params }
  return {
    type: UPDATE_MENTOR_PROFILE_INIT,
    payload
  }
}

export const deleteMentorPostSuccess = (data: any) => {
  return {
    type: DELETE_MENTOR_POST_SUCCESS,
    data
  }
}

export const deleteMentorPostFailure = (error: any) => {
  return {
    type: DELETE_MENTOR_POST_FAILURE,
    error
  }
}

export const deleteMentorPost = (params: any) => {
  const payload = { params }
  return {
    type: DELETE_MENTOR_POST_INIT,
    payload
  }
}