import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDliNH1pqiqe_LgLKvXd4UF9aX_8LS7x2s",
    authDomain: "snipe-staging.firebaseapp.com",
    projectId: "snipe-staging",
    storageBucket: "snipe-staging.appspot.com",
    messagingSenderId: "283175171749",
    appId: "1:283175171749:web:ba4b131e95a72a9914bf4c",
    measurementId: "G-LENC8WHP5P",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig, "snipe-staging");
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);