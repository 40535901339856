import { useUserAuth } from "../../hooks/useAuthContextProvider";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faEnvelope, faBell, faGraduationCap, faUsers, faArrowRight, faEdit } from "@fortawesome/free-solid-svg-icons"
import Post from "../../components/posts/post";
import Bg from "../../assets/png/bg.png";
import User from "../../assets/png/user.png";
import { useEffect, useState } from "react";
import { getMentor, getMentorPosts } from "../../redux/mentors/mentorsActions";
import logo from "../../assets/png/snipe.png";
import PostModal from "../../components/posts/postModal";
import Tick from "../../assets/svg/tick.svg";
import ProfileModal from "../../components/profile/profileModal";

const Home = () => {
  const { user } = useUserAuth();
  const dispatch = useDispatch()

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<any>({});

  const [selectedPost, setSelectedPost] = useState<string>("")

  const [isProfileModalOpen, setIsProfileModalOpen] = useState<boolean>(false);
  const [profileModalContent, setProfileModalContent] = useState<any>({})

  useEffect(() => {
    dispatch(getMentor({
      mentorId: user.uid
    }))
    dispatch(getMentorPosts({
      mentorId: user.uid
    }))
  }, [])

  const {
    isGettingMentor,
    getMentorResp,
    getMentorRespStatus,
    getMentorPostsResp,
    getMentorError,
    getMentorErrorMsg,
    getMentorPostsRespStatus,
    isAddingMentorPost,
    isGettingMentorPosts,
    getMentorPostsError,
    getMentorPostsErrorMsg,
    addMentorPostRespStatus,
    isUpdatingMentorPost,
    updateMentorPostRespStatus,
    isUpdatingMentorProfile,
    updateMentorProfileRespStatus,
    isDeletingMentorPost,
    deleteMentorPostRespStatus
  } = useSelector((state: any) => state.mentors)

  useEffect(() => {
    if (updateMentorProfileRespStatus === 200) {
      dispatch(getMentor({
        mentorId: user.uid
      }))
    }
  }, [isUpdatingMentorProfile])

  useEffect(() => {
    if (addMentorPostRespStatus === 200 || updateMentorPostRespStatus === 200 || deleteMentorPostRespStatus) {
      dispatch(getMentorPosts({
        mentorId: user.uid
      }))
    }
  }, [isAddingMentorPost, isUpdatingMentorPost, isDeletingMentorPost])

  return (
    <div>
      <div className="relative">
        <img className="h-[200px] md:h-[300px] w-full object-cover rounded-t-[10px] md:rounded-t-[20px]" src={getMentorResp?.bannerImage || Bg} alt="banner" />
        <div className="rounded-[50%]  border-4 border-white w-fit absolute bottom-[-55px] md:bottom-[-100px] left-[2.5%]">
          <div className="relative">
            <img className="rounded-[50%] h-[110px] md:h-[200px] w-[110px] md:w-[200px] bg-white" src={getMentorResp?.image || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} alt="user" />
            <img className="absolute w-[25px] md:w-[30px] right-[0px] md:right-[15px] bottom-[10px]" src={Tick} alt="tick" />
          </div>
        </div>
      </div>
      <div className="flex md:justify-end px-5 md:pl-[0px] mt-[70px] md:mt-5">
        <div className="flex gap-3">
          <div className="h-[35px] w-[35px] lg:h-[50px] h-[30px] w-[30px] lg:w-[50px] rounded-[50%] justify-center items-center flex  border border-[#536471] cursor-pointer" onClick={() => {
            setProfileModalContent(getMentorResp)
            setIsProfileModalOpen(true)
          }}>
            <FontAwesomeIcon className="text-[#536471] text-lg lg:text-xl" icon={faEdit} />
          </div>
          {/* <div className="h-[35px] w-[35px] lg:h-[50px] h-[30px] w-[30px] lg:w-[50px] rounded-[50%] justify-center items-center flex  border border-[#536471]">
            <FontAwesomeIcon className="text-[#536471] text-lg lg:text-xl" icon={faEnvelope} />
          </div>
          <div className="h-[35px] w-[35px] lg:h-[50px] h-[30px] w-[30px] lg:w-[50px] rounded-[50%] justify-center items-center flex  border border-[#536471]">
            <FontAwesomeIcon className="text-[#536471] text-lg lg:text-xl" icon={faBell} />
          </div> */}
          <div className="h-[35px] lg:h-[50px] px-5 text-lg lg:text-xl font-bold rounded-[30px] justify-center items-center flex  border border-[#536471] text-[#536471]">
            Following
          </div>
        </div>
      </div>
      {isGettingMentor && <div className="my-5 flex items-center justify-center">
        Getting your profile...
      </div>}
      {getMentorError && <div className="my-5 flex items-center justify-center">
        {getMentorErrorMsg}
      </div>}
      {getMentorRespStatus === 200 && <div className="text-sm md:text-base px-5 lg:px-10 mt-5 md:mt-[50px]">
        <div>
          {getMentorResp.description}
        </div>
        <div className="mt-5">
          <div className="flex items-center gap-1">
            <FontAwesomeIcon icon={faGraduationCap} />
            {getMentorResp.yearOfStudy}
          </div>
          <div className="flex items-center gap-1 mt-2">
            <FontAwesomeIcon icon={faUsers} />
            Followers Count
          </div>
        </div>
        <div className="grid grid-cols-5 mt-10">
          <div className="col-span-5 md:col-span-3 md:pr-5">
            {getMentorPostsError && <div className="flex items-center justify-center">
              {getMentorPostsErrorMsg}
            </div>}
            {isGettingMentorPosts && <div className="flex items-center justify-center">
              Getting your posts...
            </div>}
            {getMentorPostsRespStatus === 200 && getMentorPostsResp.map((post: any) => {
              return (
                <Post key={post.postId} post={post} getMentorResp={getMentorResp} setIsModalOpen={setIsModalOpen} setModalContent={setModalContent} selectedPost={selectedPost} setSelectedPost={setSelectedPost} />
              )
            })}
          </div>
          <div className="col-span-5 md:col-span-2">
            <div className="relative mb-5">
              <img className="w-full h-[200px] rounded object-cover" src={Bg} alt="bg" />
              <div className="absolute w-full flex flex-row md:flex-col lg:flex-row items-center justify-center gap-4 top-[30%] md:top-[10%] lg:top-[30%]">
                <img className="w-[100px] object-cover" src={User} alt="user" />
                <button className="outline-none border-none text-white font-bold bg-gradient-to-r from-[#C438EF] to-[#FF409A] px-5 py-2 rounded" onClick={() => setIsModalOpen(true)}>
                  <div className="text-sm md:text-md flex items-center gap-2">
                    CREATE POST
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                </button>
              </div>
            </div>
            <div className="rounded mb-5 overflow-hidden">
              <div className="text-sm md:text-md bg-[#A6CFF5] pl-3 py-1 md:py-2 font-bold">
                CONNECT WITH OTHER MENTORS
              </div>
              <div className="h-[300px] bg-[#D9D9D9]">

              </div>
            </div>
            <div className="rounded mb-5 overflow-hidden">
              <div className="text-sm md:text-md bg-[#A6CFF5] pl-3 py-1 md:py-2 font-bold">
                REFER AND EARN
              </div>
              <div className="h-[300px] bg-[#D9D9D9]">

              </div>
            </div>
            <div className="rounded mb-5 overflow-hidden">
              <div className="text-sm md:text-md bg-[#A6CFF5] pl-3 py-1 md:py-2 font-bold">
                FOLLOW US
              </div>
              <div className="h-[300px] bg-[#D9D9D9]">

              </div>
            </div>
          </div>
        </div>
      </div>}
      <PostModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} modalContent={modalContent} setModalContent={setModalContent} />
      <ProfileModal isProfileModalOpen={isProfileModalOpen} setIsProfileModalOpen={setIsProfileModalOpen} profileModalContent={profileModalContent} setProfileModalContent={setProfileModalContent} />
    </div>
  )
}

export default Home;