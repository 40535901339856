import {
  put, call,
  takeLatest,
} from 'redux-saga/effects';
import { baseURL } from '../../utils/apiEndPoints';
import { postRequest } from '../../utils/apiRequests';
import { db } from '../../utils/firebaseConfig';
import { getDoc, doc } from "firebase/firestore";
import {
  GET_OTP_INIT,
  VERIFY_OTP_INIT,
  GET_USER_INIT,
} from '../actionTypes';
import {
  getOTPSuccess,
  getOTPFailure,
  verifyOTPSuccess,
  verifyOTPFailure,
  getUserSuccess,
  getUserFailure,
} from './authActions';

function* getOTP(action: any): any {
  try {
    const headers = {
      'Content-Type': 'application/json',
    }
    const body = action.payload.params;
    const url = `https://us-central1-tsoappnew.cloudfunctions.net/getPhoneOtpReact`;
    const data = yield call(postRequest, url, body, headers);
    if (!data.error) {
      yield put(getOTPSuccess(data));
    } else {
      yield put(getOTPFailure(data));
    }
  } catch (err: any) {
    yield put(getOTPFailure(err.message));
  }
}

export function* getOTPSaga() {
  yield takeLatest(GET_OTP_INIT, getOTP);
}

function* verifyOTP(action: any): any {
  try {
    const headers = {
      'Content-Type': 'application/json',
    }
    const url = `https://us-central1-tsoappnew.cloudfunctions.net/verifyOtpReact`;
    const data = yield call(postRequest, url, action.payload.params, headers);
    if (!data.error) {
      yield put(verifyOTPSuccess(data));
    } else {
      yield put(verifyOTPFailure(data.message));
    }
  } catch (err: any) {
    console.log(err)
    yield put(verifyOTPFailure(err.message));
  }
}

export function* verifyOTPSaga() {
  yield takeLatest(VERIFY_OTP_INIT, verifyOTP);
}

function* getUser(action: any): any {
  try {
    const user = async () => {
      const docRef = doc(db, "user", action.payload.params.id);
      const response = await getDoc(docRef);
      if (!response) {
        return {
          error: true,
          message: "Something went wrong",
        };
      }
      return response;
    }
    const data = yield call(user);
    if (!data.error) {
      yield put(getUserSuccess(data.data()));
    } else {
      yield put(getUserFailure(data.message));
    }
  } catch (err: any) {
    console.log(err)
    yield put(getUserFailure(err.message));
  }
}

export function* getUserSaga() {
  yield takeLatest(GET_USER_INIT, getUser);
}