export const GET_OTP_INIT = 'GET_OTP_INIT';
export const GET_OTP_SUCCESS = 'GET_OTP_SUCCESS';
export const GET_OTP_FAILURE = 'GET_OTP_FAILURE';

export const VERIFY_OTP_INIT = 'VERIFY_OTP_INIT';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE';

export const FIREBASE_SIGNED_OUT = "FIREBASE_SIGNED_OUT";

export const GET_USER_INIT = 'GET_USER_INIT';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const GET_MENTOR_INIT = "GET_MENTOR_INIT";
export const GET_MENTOR_SUCCESS = "GET_MENTOR_SUCCESS";
export const GET_MENTOR_FAILURE = "GET_MENTOR_FAILURE";

export const GET_MENTOR_POSTS_INIT = "GET_MENTOR_POSTS_INIT";
export const GET_MENTOR_POSTS_SUCCESS = "GET_MENTOR_POSTS_SUCCESS";
export const GET_MENTOR_POSTS_FAILURE = "GET_MENTOR_POSTS_FAILURE";

export const ADD_MENTOR_POST_INIT = "ADD_MENTOR_POST_INIT";
export const ADD_MENTOR_POST_SUCCESS = "ADD_MENTOR_POST_SUCCESS";
export const ADD_MENTOR_POST_FAILURE = "ADD_MENTOR_POST_FAILURE";

export const UPDATE_MENTOR_POST_INIT = "UPDATE_MENTOR_POST_INIT";
export const UPDATE_MENTOR_POST_SUCCESS = "UPDATE_MENTOR_POST_SUCCESS";
export const UPDATE_MENTOR_POST_FAILURE = "UPDATE_MENTOR_POST_FAILURE";

export const RESET_MENTOR_POST_RESPONSES = "RESET_MENTOR_POSTS_RESPONSES";

export const UPDATE_MENTOR_PROFILE_INIT = "UPDATE_MENTOR_PROFILE_INIT"
export const UPDATE_MENTOR_PROFILE_SUCCESS = "UPDATE_MENTOR_PROFILE_SUCCESS"
export const UPDATE_MENTOR_PROFILE_FAILURE = "UPDATE_MENTOR_PROFILE_FAILURE"

export const DELETE_MENTOR_POST_INIT = "DELETE_MENTOR_POST_INIT"
export const DELETE_MENTOR_POST_SUCCESS = "DELETE_MENTOR_POST_SUCCESS"
export const DELETE_MENTOR_POST_FAILURE = "DELETE_MENTOR_POST_FAILURE"