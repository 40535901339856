import { faEllipsis, faHeart, faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tick from "../../assets/svg/tick.svg";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteMentorPost } from "../../redux/mentors/mentorsActions";

TimeAgo.addDefaultLocale(en)

// Create formatter (English).
const timeAgo = new TimeAgo('en-US')

const Post = ({
  post,
  getMentorResp,
  setIsModalOpen,
  setModalContent,
  selectedPost,
  setSelectedPost
}: any) => {

  const [open, setOpen] = useState<boolean>(false);
  const [touchStart, setTouchStart] = useState<number>(0);
  const [touchEnd, setTouchEnd] = useState<number>(0);

  const dispatch = useDispatch()
  const {
    isDeletingMentorPost,
    deleteMentorPostRespStatus,
    deleteMentorPostError,
    deleteMentorPostErrorMsg
  } = useSelector((state: any) => state.mentors)

  useEffect(() => {
    if (deleteMentorPostError) {
      alert(deleteMentorPostErrorMsg)
    }
  }, [isDeletingMentorPost])

  const handleEdit = () => {
    setModalContent(post)
    setIsModalOpen(true)
  }

  const handleDelete = () => {
    if (window.confirm("Delete post?")) {
      dispatch(deleteMentorPost({
        mentorId: post.mentorId,
        mentorPost: post.postId
      }))
    }
  }

  const getYoutubeId = (url: string) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[7].length == 11) ? match[7] : false;
  }

  const [carouselImage, setCarouselImage] = useState<number>(0)

  return (
    <div className="bg-gradient-to-r from-[#FFF5EE] to-[#DFDEFD] mb-5 rounded">
      <div className="flex items-center justify-between px-2 py-2">
        <div className="flex items-center gap-2">
          <div className="relative">
            <img className="rounded-[50%] h-[50px] w-[50px] bg-white" src={getMentorResp?.image || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} alt="user" />
            <div className="absolute bottom-0 right-0">
              <img src={Tick} alt="tick" className="h-[20px] w-[20px]" />
            </div>
          </div>
          <div className="md:text-lg font-bold">
            {getMentorResp?.mentorName}
          </div>
        </div>
        <div className="relative text-xs md:text-sm flex items-center gap-3">
          {timeAgo.format(post.createdAt.toDate(), "round")}
          <FontAwesomeIcon className="text-xl cursor-pointer" icon={faEllipsis} onClick={() => {
            if (open === false) {
              setSelectedPost(post.postId)
            } else {
              setSelectedPost("")
            }
            setOpen(!open)
          }} />
          {open && <div className="absolute bg-white top-5 right-0">
            <div className="cursor-pointer px-5 py-2" onClick={handleEdit}>
              Edit
            </div>
            <div className="cursor-pointer px-5 py-2" onClick={handleDelete}>
              {isDeletingMentorPost && selectedPost === post.postId ? <div className="spinner-border animate-spin inline-block w-8 h-8 border-whtie-4 rounded-full" role="status">
              </div> : "Delete"}
            </div>
          </div>}
        </div>
      </div>
      <div>
        {post.type === 1 && post?.images?.length > 1 ? <div onMouseDown={(e: any) => setTouchStart(e.clientX)} onMouseMoveCapture={(e: any) => setTouchEnd(e.clientX)} onMouseUp={() => {
          if (touchEnd > touchStart) {
            if (carouselImage > 0) {
              setCarouselImage(carouselImage - 1)
            } else {
              setCarouselImage(post?.images?.length - 1)
            }
          } else {
            if (carouselImage < post?.images?.length - 1) {
              setCarouselImage(carouselImage + 1)
            } else {
              setCarouselImage(0)
            }
          }
        }}>
          <div className="pointer-events-none select-none">
            <img className="w-full object-cover" src={post?.images[carouselImage]} alt="post" />
          </div>
          <div className="flex gap-1 items-center justify-center mt-3">
            {post?.images?.map((each: any, index: number) => {
              return (
                <div className={" w-[10px] h-[10px] rounded-[50%] cursor-pointer" + (index === carouselImage ? " bg-[#909090]" : " bg-[#C1C1C1]")} onClick={() => setCarouselImage(index)}>
                </div>
              )
            })}
          </div>
        </div> : <img className="w-full object-cover" src={post?.image} alt="post" />}
        {post.type === 2 && <iframe width="100%" height="315"
          src={`https://www.youtube.com/embed/${getYoutubeId(post?.video)}`}>
        </iframe>}
      </div>
      <div className="px-2 pb-3">
        <div className="mt-3">
          <div className="text-xs md:text-sm flex justify-between items-center">
            <div className="flex items-center gap-2">
              <FontAwesomeIcon className="text-xl" icon={faHeart} />
              <FontAwesomeIcon className="text-xl" icon={faShare} />
            </div>
            <div>
              {(post?.views / 1000).toFixed(1)}k views
            </div>
          </div>
          {post?.likes > 0 ? `${post?.likes} likes` : "No likes"}
        </div>
        <div className="mt-3">
          <div className="h3 md:text-lg font-bold">
            {post?.title}
          </div>
          <div className="text-xs md:text-sm">
            {post?.description}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Post;