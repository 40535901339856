import { faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import logo from "../../assets/png/snipe.png";
import { useUserAuth } from "../../hooks/useAuthContextProvider";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { firebaseSignedOut } from "../../redux/auth/authActions";


const Navbar = () => {
  const timeOptions: any = {
    hour: "numeric",
    minute: "numeric"
  }

  const dateOptions: any = {
    weekday: "short",
    month: "short",
    day: "numeric"
  }
  const [time, setTime] = useState(new Date().toLocaleTimeString("en-IN", timeOptions))
  const [date, setDate] = useState(new Date().toLocaleDateString("en-IN", dateOptions))
  const [openSettings, setOpenSettings] = useState<boolean>(false);

  const { user, logOut } = useUserAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setInterval(() => {
      setTime(new Date().toLocaleTimeString("en-IN", timeOptions))
      setDate(new Date().toLocaleDateString("en-IN", dateOptions))
    }, 1000);
  }, []);

  return (
    <div className="h-[100px] text-white text-sm md:text-xl">
      <div className="flex justify-between items-center h-full px-10">
        <div>
          <a href="https://snipeit.club" target="_blank" rel="noreferrer">
            <img src={logo} alt="logo" className="w-[120px] md:w-[200px] cursor-pointer" />
          </a>
        </div>
        <div className="flex items-center gap-2 md:gap-5">
          {`${time} | ${date}`}
          {user && <>
            <FontAwesomeIcon icon={faGear} className="cursor-pointer relative" onClick={() => {
              setOpenSettings(!openSettings);
            }} />
            {openSettings && <div className="flex flex-col absolute bg-white rounded text-black px-5 py-1 text-xs md:text-sm custor-pointer right-[45px] mt-[70px]">
              <div className="cursor-pointer" onClick={async () => {
                await logOut();
                dispatch(firebaseSignedOut());
                navigate("/login");

              }}>
                Logout
              </div>
            </div>}
          </>}
        </div>
      </div>
    </div>
  )
}

export default Navbar;