import React from "react";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../hooks/useAuthContextProvider";
import { firebaseSignedOut } from "../../redux/auth/authActions";
const ProtectedRoute = ({ children }: any) => {
  const { user, userDetails, logOut } = useUserAuth();
  const navigate = useNavigate()
  const dispatch = useDispatch()

  if (user) {
    if (userDetails?.name === undefined) {
      return (
        <div className="h-[100vh] flex justify-center items-center text-white">
          Fetching details
        </div>
      )
    } else if (userDetails?.userType !== 2) {
      return (
        <div className="h-[100vh] flex justify-center items-center text-white">
          <div className="flex flex-col justify-center items-center">
            User needs to be a teacher
            <button className="border-none outline-none bg-[#770000] rounded text-white font-bold px-5 py-2" onClick={async () => {
              await logOut()
              dispatch(firebaseSignedOut())
              navigate("/login")
            }}>
              Logout
            </button>
          </div>
        </div >
      )
    }
  }

  if (!user && userDetails?.email === undefined) {
    return <Navigate to="/login" />;
  }

  return children;
};
export default ProtectedRoute;