import {
  combineReducers,
} from 'redux';
import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from '@redux-saga/core';
// import snackbar from './containers/common/snackbarReducer';\
import auth from './auth/authReducer';
import mentors from './mentors/mentorsReducer';
import rootSaga from './rootSaga';

const reducers = combineReducers({
  // snackbar
  auth,
  mentors
});
const sagaMiddleware = createSagaMiddleware();

// const store = configureStore({ reducer: reducers, middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(applyMiddleware(sagaMiddleware)) });
const store = configureStore({
  reducer: reducers,
  middleware: [sagaMiddleware]
})
sagaMiddleware.run(rootSaga);

export default store;