import {
  GET_MENTOR_INIT,
  GET_MENTOR_SUCCESS,
  GET_MENTOR_FAILURE,
  GET_MENTOR_POSTS_INIT,
  GET_MENTOR_POSTS_SUCCESS,
  GET_MENTOR_POSTS_FAILURE,
  ADD_MENTOR_POST_INIT,
  ADD_MENTOR_POST_SUCCESS,
  ADD_MENTOR_POST_FAILURE,
  UPDATE_MENTOR_POST_INIT,
  UPDATE_MENTOR_POST_SUCCESS,
  UPDATE_MENTOR_POST_FAILURE,
  RESET_MENTOR_POST_RESPONSES,
  UPDATE_MENTOR_PROFILE_INIT,
  UPDATE_MENTOR_PROFILE_SUCCESS,
  UPDATE_MENTOR_PROFILE_FAILURE,
  DELETE_MENTOR_POST_INIT,
  DELETE_MENTOR_POST_SUCCESS,
  DELETE_MENTOR_POST_FAILURE
} from '../actionTypes';
import { IMentorsState } from '../../models/mentors/mentors';

const initialState = {
  isGettingMentor: false,
  getMentorResp: {},
  getMentorRespStatus: 0,
  getMentorError: false,
  getMentorErrorMsg: "",
  isGettingMentorPosts: false,
  getMentorPostsResp: [],
  getMentorPostsRespStatus: 0,
  getMentorPostsError: false,
  getMentorPostsErrorMsg: "",
  isAddingMentorPost: false,
  addMentorPostResp: {},
  addMentorPostRespStatus: 0,
  addMentorPostError: false,
  addMentorPostErrorMsg: "",
  isUpdatingMentorPost: false,
  updateMentorPostResp: {},
  updateMentorPostRespStatus: 0,
  updateMentorPostError: false,
  updateMentorPostErrorMsg: "",
  isUpdatingMentorProfile: false,
  updateMentorProfileResp: {},
  updateMentorProfileRespStatus: 0,
  updateMentorProfileError: false,
  updateMentorProfileErrorMsg: "",
  isDeletingMentorPost: false,
  deleteMentorPostResp: {},
  deleteMentorPostRespStatus: 0,
  deleteMentorPostError: false,
  deleteMentorPostErrorMsg: "",
}

const mentors = (state: IMentorsState = initialState, action: any) => {
  switch (action.type) {

    case GET_MENTOR_INIT:
      return {
        ...state,
        isGettingMentor: true,
        getMentorError: false,
        getMentorRespStatus: 0
      };

    case GET_MENTOR_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettingMentor: false,
        getMentorResp: data,
        getMentorRespStatus: 200,
      };
    }

    case GET_MENTOR_FAILURE:
      const { error } = action;
      return {
        ...state,
        isGettingMentor: false,
        getMentorError: true,
        getMentorErrorMsg: error?.message,
        getMentorRespStatus: error?.status,
      };

    case GET_MENTOR_POSTS_INIT:
      return {
        ...state,
        isGettingMentorPosts: true,
        getMentorPostsError: false,
        getMentorPostsRespStatus: 0
      };

    case GET_MENTOR_POSTS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettingMentorPosts: false,
        getMentorPostsResp: data,
        getMentorPostsRespStatus: 200,
      };
    }

    case GET_MENTOR_POSTS_FAILURE:
      return {
        ...state,
        isGettingMentorPosts: false,
        getMentorPostsError: true,
        getMentorPostsErrorMsg: action?.error?.message,
        getMentorPostsRespStatus: action?.error?.status,
      };

    case ADD_MENTOR_POST_INIT:
      return {
        ...state,
        isAddingMentorPost: true,
        addMentorPostError: false,
        addMentorPostRespStatus: 0
      };

    case ADD_MENTOR_POST_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isAddingMentorPost: false,
        addMentorPostResp: data,
        addMentorPostRespStatus: 200,
      };
    }

    case ADD_MENTOR_POST_FAILURE:
      return {
        ...state,
        isAddingMentorPost: false,
        addMentorPostError: true,
        addMentorPostErrorMsg: action?.error?.message,
        addMentorPostRespStatus: action?.error?.status,
      };

    case UPDATE_MENTOR_POST_INIT:
      return {
        ...state,
        isUpdatingMentorPost: true,
        updateMentorPostError: false,
        updateMentorPostRespStatus: 0
      };

    case UPDATE_MENTOR_POST_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isUpdatingMentorPost: false,
        updateMentorPostResp: data,
        updateMentorPostRespStatus: 200,
      };
    }

    case UPDATE_MENTOR_POST_FAILURE:
      return {
        ...state,
        isUpdatingMentorPost: false,
        updateMentorPostError: true,
        updateMentorPostErrorMsg: action?.error?.message,
        updateMentorPostRespStatus: action?.error?.status,
      };

    case RESET_MENTOR_POST_RESPONSES: {
      return {
        ...state,
        addMentorPostRespStatus: 0,
        updateMentorPostRespStatus: 0
      }
    }

    case UPDATE_MENTOR_PROFILE_INIT:
      return {
        ...state,
        isUpdatingMentorProfile: true,
        updateMentorProfileError: false,
        updateMentorProfileRespStatus: 0
      };

    case UPDATE_MENTOR_PROFILE_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isUpdatingMentorProfile: false,
        updateMentorProfileResp: data,
        updateMentorProfileRespStatus: 200,
      };
    }

    case UPDATE_MENTOR_PROFILE_FAILURE:
      return {
        ...state,
        isUpdatingMentorProfile: false,
        updateMentorProfileError: true,
        updateMentorProfileErrorMsg: action?.error?.message,
        updateMentorProfileRespStatus: action?.error?.status,
      };

    case DELETE_MENTOR_POST_INIT:
      return {
        ...state,
        isDeletingMentorPost: true,
        deleteMentorPostError: false,
        deleteMentorPostRespStatus: 0
      };

    case DELETE_MENTOR_POST_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isDeletingMentorPost: false,
        deleteMentorPostResp: data,
        deleteMentorPostRespStatus: 200,
      };
    }

    case DELETE_MENTOR_POST_FAILURE:
      return {
        ...state,
        isDeletingMentorPost: false,
        deleteMentorPostError: true,
        deleteMentorPostErrorMsg: action?.error?.message,
        deleteMentorPostRespStatus: action?.error?.status,
      };

    default:
      return state;
  }
};

export default mentors;
